import { IDestroyable } from '@entities/shared/destroyable.interface';
import { Subscription } from 'rxjs';

export class SubscriptionManager implements IDestroyable {
  private subscriptions: Subscription[] = [];
  public addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  public removeSubscription(subscription: Subscription): void {
    const index = this.subscriptions.indexOf(subscription);
    if (index > -1) {
      const subscription = this.subscriptions[index];
      subscription.unsubscribe();
      this.subscriptions.splice(index, 1);
    }
  }

  public onDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
